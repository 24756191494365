import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import api from '../utils/api'; 
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/es';
import { getStatusDetails } from '../utils/statusUtils'; // Importa la función getStatusDetails
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import './Historial.css';

moment.locale('es');

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const Historial = () => {
  const [chartData, setChartData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [consulta, setConsulta] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchConsulta = async () => {
      try {
        setIsLoading(true);
        const response = await api.get('/api/monitoreo/');
        const fetchedData = response.data.data;
        setConsulta(fetchedData);

        const lastPoint = fetchedData[fetchedData.length - 1];
        const maxPoten = Math.max(...fetchedData.map((point) => point.poten));
        const { color, description } = getStatusDetails(lastPoint.err);

        const todayStart = moment().startOf('day');
        const todayEnd = moment().endOf('day');
        const todayData = fetchedData.filter((point) => {
          const pointDate = moment(point.fecha);
          return pointDate.isBetween(todayStart, todayEnd, null, '[]');
        });

        const maxPotenToday =
          todayData.length > 0
            ? Math.max(...todayData.map((point) => point.poten))
            : 0;

        setData({
          estadoOperacion: description,
          estadoOperacionColor: color, // Usar el color dinámico basado en el código de estado
          potenciaMaxima: `${maxPotenToday} W`,
          ultimoRegistro: moment(lastPoint.fecha).format('DD/MM/YYYY HH:mm:ss'),
          temperaturaClima: `${lastPoint.tade}°C`,
        });
        setIsLoading(false);
        console.log('Consulta:', fetchedData);
      } catch (error) {
        console.error('Error fetching consulta:', error);
        setIsLoading(false);
      }
    };

    fetchConsulta();
  }, []);

  const handleGraficar = () => {
    fetchData();
  };

  const fetchData = () => {
    if (!consulta) return;
    setIsLoading(true);
    const start = moment(startDate).startOf('day');
    const end = moment(endDate).endOf('day');
    const filteredData = consulta.filter((point) => {
      const pointDate = moment(point.fecha);
      return pointDate.isBetween(start, end, null, '[]');
    });
    setChartData(filteredData);
    setIsLoading(false);
  };

  const dataLine = {
    labels: chartData.map((point) =>
      moment(point.fecha).format('DD/MM/YYYY HH:mm:ss')
    ),
    datasets: [
      {
        label: 'Códigos de estado',
        data: chartData.map((point) => point.err),
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const optionsLine = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Códigos de error',
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Fecha',
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Códigos de Estado',
        },
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          callback: function (value) {
            return value;
          },
        },
      },
    },
  };

  const cards = [
    {
      title: 'Estado de Operación:',
      value: data.estadoOperacion,
      className: 'card-estado', // Usar la clase CSS para el color
    },
    {
      title: 'Potencia CA máxima del día:',
      value: data.potenciaMaxima,
      className: 'card-potencia', // Usar la clase CSS para el color
    },
    {
      title: 'Fecha del último registro:',
      value: data.ultimoRegistro,
      className: 'card-fecha', // Usar la clase CSS para el color
    },
    {
      title: 'Temperatura Interna del Inversor:',
      value: data.temperaturaClima,
      className: 'card-temperatura', // Usar la clase CSS para el color
    },
  ];

  return (
    <div className="historial-container">
      <h1>Historial de Códigos de Estado</h1>
      <div className="content-wrapper">
        <div className="chart-wrapper">
          <div className="controls">
            <div className="controlItem">
              <label>Fecha Inicio: </label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd/MM/yyyy"
                locale="es"
              />
            </div>
            <div className="controlItem">
              <label>Fecha Final: </label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="dd/MM/yyyy"
                locale="es"
              />
            </div>
            <div className="controlItem graficarButton">
              <button onClick={handleGraficar}>Graficar</button>
            </div>
          </div>
          <div className="chartContainer">
            {isLoading ? (
              <div className="loading">Cargando datos...</div>
            ) : (
              <Line data={dataLine} options={optionsLine} />
            )}
          </div>
        </div>
        <div className="cards-wrapper">
          <div className="historial-cards">
            {cards.map((card, index) => (
              <div
                key={index}
                className={`card ${card.className}`}
              >
                <div className="card-title">{card.title}</div>
                <div className="card-value">{card.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Historial;


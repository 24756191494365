export const getStatusDetails = (err) => {
    if (err === 40) return { color: 'green', description: 'Normal' };
    
    const redErrors = {
        1: 'Protección Isla',
        2: 'Sobrecorriente CA',
        3: 'Sobre Voltaje bus CD-HW',
        4: 'Voltaje CA bajo UV1',
        5: 'Voltaje CA bajo UV2',
        6: 'Voltaje CA alto OV1',
        7: 'Voltaje CA alto OV2',
        8: 'Frecuencia de red baja',
        9: 'Frecuencia de red alta',
        10: 'Temperatura alta',
        11: 'Variación de frecuencia de red',
        21: 'Voltaje CD bajo',
        22: 'Voltaje CD alto',
        23: 'Sobrecorriente CD',
        24: 'Sobrevoltaje Bus CD-SW'
    };
    
    if (redErrors[err]) return { color: 'red', description: redErrors[err] };

    const yellowErrors = {
        25: 'Inicializando DSP',
        31: 'Sin conexión a WI-FI',
        32: 'Error inicio RTC',
        33: 'Error SD CARD',
        34: 'Sin conexión a servidor'
    };
    
    if (yellowErrors[err]) return { color: 'yellow', description: yellowErrors[err] };
    
    return { color: 'gray', description: 'Desconocido' };
};
import React, { useState, useEffect } from 'react';
import './Home.css';
import Card from '../components/Card';
import api from '../utils/api'; // Asegúrate de que tu API esté correctamente importada
import moment from 'moment';
import { getStatusDetails } from '../utils/statusUtils';

const Home = () => {
    const [cardsData, setCardsData] = useState([
        {
            id: 1,
            numeroInversor: 'PV201-CV1.101-I10T3-300424',
            fecha: '',
            estado: '',
            color: '',
            actualizado: ''
        },
        {
            id: 2,
            numeroInversor: 'PV202-CV1.101-I10T3-300424',
            fecha: '',
            estado: 'Sin conexión a servidor',
            color: 'yellow',
            actualizado: ''
        },
        {
            id: 3,
            numeroInversor: 'PV203-CV1.101-I10T3-300424',
            fecha: '',
            estado: 'Sin conexión a servidor',
            color: 'yellow',
            actualizado: ''
        },
        {
            id: 4,
            numeroInversor: 'PV204-CV1.101-I10T3-300424',
            fecha: '',
            estado: 'Sin conexión a servidor',
            color: 'yellow',
            actualizado: ''
        }
    ]);

    useEffect(() => {
        const fetchDataForCard1 = async () => {
            try {
                const response = await api.get('/api/monitoreo/');
                const fetchedData = response.data.data;
                const lastPoint = fetchedData[fetchedData.length - 1];
                const { color, description } = getStatusDetails(lastPoint.err);

                setCardsData(prevCardsData =>
                    prevCardsData.map(card =>
                        card.id === 1
                            ? {
                                ...card,
                                fecha: moment(lastPoint.fecha).format('DD/MM/YYYY HH:mm:ss'),
                                estado: description,
                                color: color,
                                actualizado: moment().format('HH:mm:ss')
                            }
                            : card
                    )
                );
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchDataForCard1();
    }, []);

    const highlightedCardId = 1; // ID del Card que NO se debe desenfocar

    return (
        <div className="home-container">
            <h1>Inicio</h1>
            <div className="cards-container">
                <div className="cards-inner-container">
                    {cardsData.map(card => (
                        <Card
                            key={card.id}
                            id={card.id}
                            numeroInversor={card.numeroInversor}
                            fecha={card.fecha}
                            estado={card.estado}
                            color={card.color}
                            actualizado={card.actualizado}
                            isHighlighted={card.id === highlightedCardId}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Home;

import React from 'react';
import './Card.css';

const Card = ({ id, numeroInversor, fecha, estado, color, actualizado, isHighlighted }) => {
    return (
        <div className={`card ${isHighlighted ? '' : 'blurred'}`}>
            <h2>Número de Inversor:</h2>
            <p className="numero-inversor">{numeroInversor}</p>
            <p className="fecha">{fecha}</p>
            <p className="actualizado">Actualizado {actualizado}</p>
            <div className="estado" style={{color: color}}>
                <span className={`estado-dot ${color}`}></span>
                {estado}
            </div>
        </div>
    );
}

export default Card;

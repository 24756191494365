import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome, FaMapMarkerAlt, FaChartBar, FaHistory, FaSignOutAlt } from 'react-icons/fa';
import { UserContext } from '../context/UserContext';
import { useAuth } from '../context/AuthContext';
import './Sidebar.css';
import ODTEC from '../assets/ODTEC.png';

const Sidebar = () => {
    const { userData } = useContext(UserContext);
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logout(); // Llama a la función logout
            navigate('/login'); // Redirige al usuario a la página de inicio de sesión
        } catch (error) {
            console.error('Logout error:', error);
            // Aquí podrías mostrar un mensaje al usuario si el cierre de sesión falla
        }
    };

    return (
        <div className="sidebar">
            <div className="sidebar-logo">
                <Link to="/perfil">
                    <img src={ODTEC} alt="Logo person" className="profile-img" />
                </Link>
                {userData && (
                    <p className="profile-name">
                        {userData.name} {userData.lastname}
                    </p>
                )}
            </div>
            <ul className="sidebar-menu">
                <li>
                    <Link to="/home">
                        <FaHome className="sidebar-icon" />
                        <span className="sidebar-text">Inicio</span>
                    </Link>
                </li>
                <li>
                    <Link to="/instalacion">
                        <FaMapMarkerAlt className="sidebar-icon" />
                        <span className="sidebar-text">Instalación</span>
                    </Link>
                </li>
                <li>
                    <Link to="/representacion">
                        <FaChartBar className="sidebar-icon" />
                        <span className="sidebar-text">Gráficos</span>
                    </Link>
                </li>
                <li>
                    <Link to="/historial">
                        <FaHistory className="sidebar-icon" />
                        <span className="sidebar-text">Historial</span>
                    </Link>
                </li>
            </ul>
            <div className="sidebar-footer">
                <button className="logout-button" onClick={handleLogout}>
                    <FaSignOutAlt className="sidebar-icon" />
                    <span className="sidebar-text">Cerrar sesión</span>
                </button>
            </div>
        </div>
    );
};

export default Sidebar;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Home from './components/Home';
import Footer from './components/Footer';
import Instalacion from './components/Instalacion';
import Representacion from './components/Representacion';
import Historial from './components/Historial';
import Perfil from './components/Perfil';
import LoginForm from './components/LoginForm';
import ProtectedRoute from './components/ProtectedRoute';
import { useAuth, AuthProvider } from './context/AuthContext';
import './App.css'; // Estilos globales

const Layout = ({ children }) => {
    const location = useLocation();
    const isLoginPage = location.pathname === '/login';
    const { isAuthenticated } = useAuth();

    if (isAuthenticated && isLoginPage) {
        return <Navigate to="/home" />;
    }

    return (
        <div className="app-container">
            {!isLoginPage && <Sidebar />}
            <div className={isLoginPage ? '' : 'content'}>
                {children}
            </div>
            {!isLoginPage && <Footer />}
        </div>
    );
};

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Layout>
                    <Routes>
                        <Route path="/login" element={<LoginForm />} />
                        <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                        <Route path="/instalacion" element={<ProtectedRoute><Instalacion /></ProtectedRoute>} />
                        <Route path="/representacion" element={<ProtectedRoute><Representacion /></ProtectedRoute>} />
                        <Route path="/historial" element={<ProtectedRoute><Historial /></ProtectedRoute>} />
                        <Route path="/perfil" element={<ProtectedRoute><Perfil /></ProtectedRoute>} />
                        <Route path="*" element={<Navigate to="/login" />} /> {/* Redirige a login si no coincide con ninguna ruta */}
                    </Routes>
                </Layout>
            </Router>
        </AuthProvider>
    );
};

export default App;

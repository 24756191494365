import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../context/UserContext';
import { useAuth } from '../context/AuthContext';
import './Perfil.css';
import ODTEC from '../assets/ODTEC.png';

const Perfil = () => {
    const { userData, setUserData } = useContext(UserContext);
    const { fetchUserData } = useAuth(); // Método para obtener datos del usuario
    const [passwords, setPasswords] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [showPasswords, setShowPasswords] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
    });
    const [error, setError] = useState('');

    useEffect(() => {
        const loadUserData = async () => {
            try {
                const data = await fetchUserData();
                setUserData(data);
            } catch (error) {
                console.error('Error fetching user data:', error);
                Swal.fire('Error', 'No se pudo obtener la información del usuario.', 'error');
            }
        };

        loadUserData();
    }, [fetchUserData, setUserData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPasswords({
            ...passwords,
            [name]: value
        });
    };

    const toggleShowPassword = (field) => {
        setShowPasswords({
            ...showPasswords,
            [field]: !showPasswords[field]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (passwords.newPassword !== passwords.confirmPassword) {
            setError('Las contraseñas no coinciden');
            return;
        }
        setError('');

        // Mostrar SweetAlert de confirmación
        Swal.fire({
            title: '¿Estás seguro de cambiar contraseña?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cambiar contraseña'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    // Lógica para actualizar la contraseña
                    // Aquí deberías llamar a tu API para actualizar la contraseña
                    console.log('Password updated:', passwords);
                    Swal.fire('Confirmado', 'Tu contraseña ha sido cambiada.', 'success');
                } catch (error) {
                    Swal.fire('Error', 'No se pudo cambiar la contraseña.', 'error');
                }
            }
        });
    };

    if (!userData) {
        return <div>Cargando...</div>; // O un componente de carga
    }

    return (
        <div className="profile-container">
            <h1>Perfil de Usuario</h1>
            <div className="profile-info">
                <img src={ODTEC} alt="Perfil" className="profile-img" />
                <p><strong>Nombre Completo:</strong> {userData.name} {userData.lastname} {userData.second_surname}</p>
                <p><strong>Correo Electrónico:</strong> {userData.email}</p>
                <p><strong>Rol:</strong> {userData.rol ? userData.rol.name : 'No disponible'}</p>
            </div>
            <form onSubmit={handleSubmit} className="password-form">
                <label>
                    Contraseña Actual:
                    <div className="password-input-container">
                        <input
                            type={showPasswords.currentPassword ? "text" : "password"}
                            name="currentPassword"
                            value={passwords.currentPassword}
                            onChange={handleInputChange}
                            required
                        />
                        <FontAwesomeIcon
                            icon={showPasswords.currentPassword ? faEyeSlash : faEye}
                            onClick={() => toggleShowPassword('currentPassword')}
                            className="toggle-password-icon"
                        />
                    </div>
                </label>
                <label>
                    Nueva Contraseña:
                    <div className="password-input-container">
                        <input
                            type={showPasswords.newPassword ? "text" : "password"}
                            name="newPassword"
                            value={passwords.newPassword}
                            onChange={handleInputChange}
                            required
                        />
                        <FontAwesomeIcon
                            icon={showPasswords.newPassword ? faEyeSlash : faEye}
                            onClick={() => toggleShowPassword('newPassword')}
                            className="toggle-password-icon"
                        />
                    </div>
                </label>
                <label>
                    Confirmar Nueva Contraseña:
                    <div className="password-input-container">
                        <input
                            type={showPasswords.confirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            value={passwords.confirmPassword}
                            onChange={handleInputChange}
                            required
                        />
                        <FontAwesomeIcon
                            icon={showPasswords.confirmPassword ? faEyeSlash : faEye}
                            onClick={() => toggleShowPassword('confirmPassword')}
                            className="toggle-password-icon"
                        />
                    </div>
                </label>
                {error && <p className="error-message">{error}</p>}
                <button type="submit">Cambiar</button>
            </form>
        </div>
    );
};

export default Perfil;

import React, { useState, useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import api from '../utils/api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/es';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import './Representacion.css';
import zoomPlugin from 'chartjs-plugin-zoom';

moment.locale('es');

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const Representacion = () => {
  const [selectedChart, setSelectedChart] = useState('Voltaje CD');
  const [chartData, setChartData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [consulta, setConsulta] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [energyToday, setEnergyToday] = useState(0);
  const [energyLast7Days, setEnergyLast7Days] = useState(0);
  const [energyLastMonth, setEnergyLastMonth] = useState(0);
  const [currentMonth, setCurrentMonth] = useState('');

  const chartTypes = [
    { label: 'Voltaje CD', key: 'vin' },
    { label: 'Corriente CD', key: 'iin' },
    { label: 'Potencia CD', key: 'potin' },
    { label: 'Voltaje bus CD', key: 'vbus' },
    { label: 'Ciclo de trabajo', key: 'dcy' },
    { label: 'Voltaje red eléctrica', key: 'vout' },
    { label: 'Corriente CA', key: 'iout' },
    { label: 'Frecuencia de red', key: 'frec' },
    { label: 'Potencia CA', key: 'poten' },
  ];

  const selectedChartKey = chartTypes.find(chart => chart.label === selectedChart)?.key;

  useEffect(() => {
    const fetchConsulta = async () => {
      try {
        setIsLoading(true);
        const response = await api.get('/api/monitoreo/');
        setConsulta(response.data.data);
        setIsLoading(false);
        calculateEnergyValues(response.data.data);
        // Actualizar datos de gráficos al cargar
        updateChartsData(response.data.data);
      } catch (error) {
        console.error('Error fetching consulta:', error);
        setIsLoading(false);
      }
    };

    fetchConsulta();
  }, []);

  const calculateEnergyValues = (data) => {
    const today = moment().startOf('day');
    const last7Days = moment().subtract(6, 'days').startOf('day');
    const lastMonth = moment().subtract(1, 'month').startOf('month');
    const endOfLastMonth = moment().subtract(1, 'month').endOf('month');

    let sumToday = 0;
    let sumLast7Days = 0;
    let sumLastMonth = 0;

    data.forEach((point) => {
      const pointDate = moment(point.fecha);
      if (pointDate.isSame(today, 'day')) {
        sumToday += point.energy;
      }
      if (pointDate.isBetween(last7Days, moment(), null, '[]')) {
        sumLast7Days += point.energy;
      }
      if (pointDate.isBetween(lastMonth, endOfLastMonth, null, '[]')) {
        sumLastMonth += point.energy;
      }
    });

    setEnergyToday(sumToday);
    setEnergyLast7Days(sumLast7Days);
    setEnergyLastMonth(sumLastMonth);
    setCurrentMonth(lastMonth.format('MMMM'));
  };

  const groupDataByDay = (data) => {
    const groupedData = {};
    data.forEach((point) => {
      const date = moment(point.fecha).startOf('day').format('YYYY-MM-DD');
      if (!groupedData[date]) {
        groupedData[date] = 0;
      }
      groupedData[date] += point.energy;
    });
    return Object.keys(groupedData).map(date => ({
      fecha: date,
      energy: groupedData[date]
    }));
  };

  const updateChartsData = (data) => {
    if (selectedChart === 'Energía Eléctrica') {
      // Si el tipo de dato seleccionado es "Energía Eléctrica", actualizamos los datos de la gráfica de barras
      setChartData(groupDataByDay(data));
    } else {
      // Filtrar y actualizar datos de la gráfica de línea
      const start = moment(startDate).startOf('day');
      const end = moment(endDate).endOf('day');
      const filteredData = data.filter((point) => {
        const pointDate = moment(point.fecha);
        return pointDate.isBetween(start, end, null, '[]');
      });
      setChartData(filteredData);
    }
  };

  const handleGraficar = () => {
    updateChartsData(consulta);
  };

  const dataLine = {
    labels: chartData.map((point) => moment(point.fecha).format('DD/MM/YYYY HH:mm:ss')),
    datasets: [
      {
        label: selectedChart,
        data: chartData.map((point) => point[selectedChartKey]),
        fill: false,
        borderColor: 'blue',
      },
    ],
  };

  const optionsLine = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: selectedChart,
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Horario',
        },
      },
      y: {
        title: {
          display: true,
          text: selectedChart,
        },
      },
    },
  };

  const dataBar = {
    labels: groupDataByDay(chartData).map((point) => moment(point.fecha).format('DD/MM/YYYY')),
    datasets: [
      {
        label: 'Energía Eléctrica',
        data: groupDataByDay(chartData).map((point) => point.energy),
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const optionsBar = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Energía Eléctrica',
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Fecha',
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Valor',
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="container">
      <h1>Gráficos de Datos</h1>
      <div className="controls">
        <div className="controlItem">
          <label>Fecha Inicio: </label>
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              handleGraficar(); // Actualizar gráficos al cambiar la fecha
            }}
            dateFormat="dd/MM/yyyy"
            locale="es"
          />
        </div>
        <div className="controlItem">
          <label>Fecha Final: </label>
          <DatePicker
            selected={endDate}
            onChange={(date) => {
              setEndDate(date);
              handleGraficar(); // Actualizar gráficos al cambiar la fecha
            }}
            dateFormat="dd/MM/yyyy"
            locale="es"
          />
        </div>
        <div className="controlItem graficarButton">
          <button onClick={handleGraficar}>Graficar</button>
        </div>
        <div className="controlItem">
          <label>Tipo de Dato: </label>
          <select onChange={(e) => setSelectedChart(e.target.value)} value={selectedChart}>
            {chartTypes.map((type) => (
              <option key={type.key} value={type.label}>
                {type.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="chartContainer">
        <Line data={dataLine} options={optionsLine} />
      </div>
      <div className="energySection">
        <div className="chartContainer">
          <Bar data={dataBar} options={optionsBar} />
        </div>
        <div className="cardsContainer">
          <div className="card card1">
            <h3>Energía de Hoy</h3>
            <p>{energyToday} Wh</p>
          </div>
          <div className="card card2">
            <h3>Energía Últimos 7 Días</h3>
            <p>{energyLast7Days} Wh</p>
          </div>
          <div className="card card3">
            <h3>Energía Último Mes ({currentMonth})</h3>
            <p>{energyLastMonth} Wh</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Representacion;

import React, { createContext, useState, useEffect } from 'react';
import api from '../utils/api'; // Asegúrate de que esta ruta es correcta

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await api.get('/api/user'); // Ajusta esta URL según tu API
                setUserData(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
                // Puedes manejar el error aquí o establecer un estado de error
            }
        };

        fetchUserData();
    }, []);

    return (
        <UserContext.Provider value={{ userData, setUserData }}>
            {children}
        </UserContext.Provider>
    );
};

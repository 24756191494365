import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { Card, CardContent, Typography,Grid, Box } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import './Instalacion.css';
import paneles from '../assets/paneles.jpeg'

// Configuración del icono del puntero del mapa
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png'
});

const center = [18.875934809573913, -99.2183637538906]; // Coordenadas de ejemplo

const Instalacion = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // ajuste backend para obtener los datos
    fetch('/api/instalacion')
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setData(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        // datos ficticios para el front
        setData({
          capacidadSFV: 1.2,  // Capacidad del SFV en kWp
          marcaModulos: "Trina Solar - TSM-425DE09R",  // Marca de los módulos fotovoltaicos
          ubicacion: "Reforma 113, Palmira, 62493 Cuernavaca, Mor.",  // Ubicación de la instalación
        });
      });
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* Título estático */}
      <h1>Instalación</h1>
      <MapContainer center={center} zoom={15} style={{ width: '100%', height: '400px' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={center}>
          <Popup>
            <div>
              <strong>Ubicación:</strong><br />
              {data.ubicacion}
            </div>
          </Popup>
        </Marker>
      </MapContainer>
      <Box display="flex" justifyContent="center" marginTop="10px">
        <Box className="ubicacion">
          <Typography variant="body1">Ubicación: {data.ubicacion}</Typography>
        </Box>
        
      </Box>
      <Grid container spacing={2} marginTop="20px">
        <Grid item xs={12} sm={6}>
          <Card sx={{ backgroundColor: '#5F9B47', border: '1px solid #ccc' }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Marca/Modelo de módulos FV:
              </Typography>
              <Typography variant="h6">
                {data.marcaModulos}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card sx={{ backgroundColor: '#6CA3E6', border: '1px solid #ccc' }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Capacidad del SFV:
              </Typography>
              <Typography variant="h6">
                {data.capacidadSFV} kWp
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" marginTop="20px">
        <img src={paneles} alt="Paneles Solares" className="imagen-paneles" />
      </Box>
      <Box display="flex" justifyContent="center" marginTop="10px">
        <Box className="ubicacion">
          <Typography variant="body1">Cuernavaca,Mor</Typography>
        </Box>
        
      </Box>
    </div>
  );
};

export default Instalacion;
